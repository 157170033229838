
.admin__container {
  font-family: "Manrope", "sans-serif";
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  min-height:100vh;
}
.admin__header {
  font-size: 4rem;
  padding: 15vh 0;
  margin: 0;
  color: var(--text-color);
  width:100%;
  text-align: center;
}
.admin__button-div {
  width:85%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  margin: 20px auto;
  gap: 10px;
}
.admin__button {
  width: 100%;  
  height: 50px; 
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #0d3a9b; 
  color: white; 
  font-size: 18px; 
  font-weight: 500;                 
  cursor: pointer; 
  transition: background-color 0.3s ease, font-size 0.3s ease; 
  z-index: 1;
}
.admin__button:hover {
  background-color: #1c57d6; 
  font-size: 21px;
}

.admin__job-list {
  background-color: var(--background-color);
  height:auto;
  color: var(--text-color);
  margin: 0 3vw;
  text-align: center;
  .admin__job-list-header {
    font-size: 2rem;
  }
  .admin__job-item-header {
    display: flex;
    justify-content: space-between;
    padding:10px;
    .admin__job-item-title{
      font-size: 1.5rem;
    }
    .admin__job-item-buttons{
      display: flex;
      justify-content: row;
      margin: auto 1vw;
      gap:30px;
      width: 50%;
      .admin_job-item-dropdown{
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
  .admin__job-item{
    background-color: white;
    color: black;
    border: 2px solid #1040c3b8;
    border-radius: 10px;
    margin-bottom: 20px;
    width:90%;
    height: auto;
    box-shadow: 0 2px 5px rgba(35, 36, 42, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    text-align: left;
    align-items: left;
    display:flex;
    flex-direction: column;
    margin: auto;
  }
  .admin__job-item:hover {
    box-shadow: 1px 1px 1px 1px rgba(43, 43, 242, 0.974);
  }

  .admin__job__details{
    margin: auto 2vw;
  }
}

#admin-add-button{
  width:65%;
}

#admin-logout-button{
  width:30%;
}

.jobapp__container{
  font-family: "Manrope", "sans-serif";
  background-color: var(--background-color);
  margin:auto;
  padding:auto;
  display: flex;
  flex-direction: column;
  height:auto;
}

.job__header{
  margin: 5vh 11%;
  width: auto;
  color: var(--text-color)
}

.job__title {
  color: var(--text-color);
  font-size: 2rem;
  text-align: center;
  color: var(--text-color)
}

.job__form{
  text-align: center;
  align-items: center;
  width: 80%;
  margin:5vh auto;
  color: var(--text-color)

}
.job__button {
  align-items: center;  
  justify-content: center; 
  gap: 8px; 
  font-family: "Manrope", sans-serif;
  width: 80%;
  max-width: 500px;
  height: 50px;
  border-radius: 25px;
  background-color: #0d3a9b; 
  color: white;
  border: none;
  font-size: 20px; 
  font-weight: 500;
  cursor: pointer; 
  transition: background-color 0.3s ease, font-size 0.3s ease; 
  z-index: 100;
}

.job__button:hover {
  background-color: #1c57d6; 
  font-size: 22px;
}
.job__form-wrapper {
  display:flex;
  flex-direction: row;
  box-sizing: border-box;
  width:100%;
  margin:10px;
  gap:10px;

}
.job__form-wrapper .job__textinput {
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 35px;
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #f9f9f9;
  color: #333;
}
.job__form-resume-wrapper{
  flex-direction: row;
  display: flex;
  width:100%;
  margin:10px;


}

.job__form-resume-wrapper .file-label {
  width:30%;
  font-size: 1rem;
  margin:auto;
}
.job__form-resume-wrapper .job__textinput {
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 35px;
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #f9f9f9;
  color: #333;
}

.job__header .h1 {
  text-align: left;
  margin-bottom: 20px;
  color: #0056b3;
  font-size: 24px;
  font-weight: bold;
}


.job__header .h2 {
  font-size: 20px;
  color: #0056b3;
  margin-bottom: 15px;
}
.job__message-response{
  color: var(--text-color);
  width:100%;
  text-align: center;
  padding: 50px 0px;
  font-size: 1.5rem;
}

@media (max-width: 800px) {
  .job__header{
    margin: 5vh 15%;
    width: auto;
  }
  .job__subheader{
    text-align: left;
  }
  .job__desc{
    text-align: left;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Patua+One');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Patua+One&family=Spectral+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Manrope:wght@200..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Patua+One&family=Spectral+SC&display=swap');

.Home {
  text-align: center;
  color: var(--text-color);                      
  font-family: "Manrope", sans-serif;
}
.home-container {
  /* background: linear-gradient(140deg,#111111, #08091a); */
  background: var(--hero-gradient);
  height:auto;
  position: relative;
}
section {
  padding: 60px 20px;
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
}
.home__hero {
  position: flex;
  height: 100vh;
  display: flex;
  flex-direction: row; 
  position: relative;
}

.home__hero-text-container {
  position: relative;
  text-align: left;
  overflow: hidden; 
  z-index: 100;
  left:0;
  height: auto;
  width: 100%;
  padding: 0 3vw;
  margin: 25vh auto;
}

.home__hero-text-title {
  font-weight: 600;
  font-style: normal;
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  z-index: 1;
  color: var(--text-color);                      
  text-transform: none;         
  line-height: 1.2;                 
  letter-spacing: 3px;              
  font-size: 5.7vw;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); 
  margin: 10px 0;                   
  left:0;
}

.home__hero-text-para {
  font-family: "Manrope", sans-serif;
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  z-index: 1;
  font-size: 1.5vw;                  
  font-weight: 500;                 
  color: var(--text-color);                      
  text-transform: none;         
  line-height: 1.2;                 
  letter-spacing: 1.5px;              
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); 
  margin: 20px 0;  
}

.home__hero-contact-text-para {
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  z-index: 1;
  font-size: 1vw;                  
  font-weight: 400;                 
  color: var(--text-color);                      
  text-transform: none;         
  line-height: 1.2;                 
  letter-spacing: 1px;              
  padding: 2vh 0 8vh 0;  
  width:50%;                 
}

.home__hero-image-container {
  position: absolute;
  top:0;
  height: 100%;
  width: 100%;
  right: 0;
  margin: auto 0;
  background: url("./Images/Hero-Image.jpg");
  background-size: cover;
  background-position: center;
  filter: brightness(var(--hero-image-brightness)); 
  -webkit-mask-image: var(--hero-image-webkit);
  mask-image: var(--hero-image-webkit);
  -webkit-mask-size: 100%;
  mask-size: 100%;

}

.home__hero-square-button {
  font-family: "Manrope", sans-serif;
  width: 250px;  
  height: 50px; 
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #0d3a9b; 
  color: white;                      
  font-size: 20px; 
  font-weight: 500;                 
  cursor: pointer; 
  transition: background-color 0.3s ease, font-size 0.3s ease; 
  z-index: 1;
  border:none;
}

.home__hero-square-button:hover {
  background-color: #1c57d6; 
  font-size: 21px;
}

.home__info-section {
  display: flex;
  flex-direction: column; 
  gap: 20px; 
  justify-content: center; 
  align-items: center; 
  height: 90vh; 
  width: 80vw; 
  margin: auto; 
  color: var(--text-color);                      
}
.home__info-button {
  display: inline-flex; 
  align-items: center;  
  justify-content: center; 
  gap: 8px; 
  font-family: "Manrope", sans-serif;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  background-color: #0d3a9b; 
  color: white;                      
  border: none;
  font-size: 20px; 
  font-weight: 500;
  cursor: pointer; 
  transition: background-color 0.3s ease, font-size 0.3s ease; 
  z-index: 100;
}


.home__info-button:hover {
  background-color: #1c57d6; 
  font-size: 21px;
}

.home__info-chevron-right {
  font-size: 1.25rem; 
  transition: transform 0.3s ease;
}

.home__info-button:hover .home__info-chevron-right {
  transform: translateX(4px); 
}

.home__info-card-container {
  display: flex; 
  text-align: left; 
  gap: 20px; 
  width: 90vw;
  height:60vh;
  padding: 20px 0;
}
.home__info-title {
  font-weight: 500; 
  color: var(--text-color);                      
  font-size: 3rem; 
  position: relative; 
  letter-spacing: .5px;
  margin:0 auto;
}

.home__info-title-underline {
  width: 0%; 
  height: 5px;
  background-color: #0d3a9b;
  margin: 0 auto;
  transition: width 0.1s ease; 
  transform-origin: left center; 
}

.home__info-card {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: var(--background-color); 
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, filter 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.home__card-content {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4); 
  padding: 0 2vw;
  border-radius: 8px;
  z-index: 1;
}

.home__card-content h3 {
  margin-top: 10px;
  color: #ffffff;
  font-size: 2.5vw;
  font-weight: 600;
}

.home__card-content .home__card-description {
  margin-top: 5px;
  color: #dcdcdc;
  font-size: 1vw;
}

.home__card-icon img {
  width: 60px;
  height: auto;
}


.home__info-card:hover {
  filter: brightness(1.1); 
}


.home__careers-section {
  position: relative; 
  color: var(--text-color);                      
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left; 
  margin-top:15vh;
  background: linear-gradient(270deg,#1c449b, #0d2250);
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 0;
  z-index: 100;

}

.home__careers-square-button {
  align-items: center;  
  text-align: center;
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: rgba(0,0,0,0);
  border: 1px solid white;
  color: white;
  font-size: 1.5rem; 
  font-weight: 500;
  margin: 20px 0;
  cursor: pointer; 
  transition: background-color 0.5s ease, color 0.5s ease, font-weight 0.5s ease; 
  z-index: 1;
}

.home__careers-square-button:hover {
  background-color: #fcfdff; 
  color: #1c449b;
  font-weight: 700;
}

.home__careers-search-icon {
  font-size: 1.5rem; 
  transition: color 0.3s ease, font-weight 0.3s ease, transform 0.3s ease;
  margin: auto 10px;
}

.home__careers-square-button:hover .home__careers-search-icon {
  transform: translateX(4px);
  color: #1c449b;
  font-weight: 700;
}
.home__careers-content {
  color: var(--text-color);                      
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center; 
  width: 100%; 
  gap: 20px; 
  z-index: 100;
}

.home__careers-image-container {
  display: flex;
  flex-direction: column;
  gap: 20px; 
  padding: 0px 10vw;
  z-index: 1;
}

.home__careers-image-container img {
  width: 25vw; 
  height: auto; 
  border-radius: 8px; 
}

.home__careers-text-container {
  padding: 0 5vw;
  color: white;                      
  margin-right: 10vw;
  justify-content: left;
  text-align: left;
  font-weight: 500; 
  z-index: 1;
}

.home__careers-text-container h2 {
  font-size: 3rem;
  font-weight: 500; 
  margin-bottom: 20px;
  color: white;                      
  justify-content: left;
  text-align: left;
  z-index: 1;
}

.home__careers-text-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: white;                      
  justify-content: left;
  text-align: left;
}

.contact-section {
  display:flex;
  flex-direction: row;
  width: 80%;
  height: 30vh;
  align-items: center;
  margin: auto;
}

.contact-text-container {
  width: 100%;
  height:auto;
  margin: 1vh auto;
  text-align: left;
  display:flex;
  flex-direction: column;
}

.contact-title {
  padding-left: 10vw;
  color: var(--text-color);                      
  text-align:left;
  font-size: 2rem;
}

.contact-para {
  padding-left: 10vw;
  color: var(--text-color);                      
  text-align: left;
  font-size: 1rem;
}

.contact-button {
  margin:auto;
  padding: auto;
  width: 80%;
}
.contactus-button {
  display: inline-flex; 
  align-items: center;  
  justify-content: center; 
  gap: 8px; 
  font-family: "Manrope", sans-serif;
  width: 250px;
  height: 50px;
  border-radius: 25px;
  background-color: #0d3a9b; 
  color: white;                      
  border: none;
  font-size: 20px; 
  font-weight: 500;
  cursor: pointer; 
  transition: background-color 0.3s ease, font-size 0.3s ease; 
  z-index: 100;
}

.contactus-button:hover {
  background-color: #1c57d6; 
  font-size: 21px;
}

@media screen and (max-width: 800px) {
  .home__hero {
    height: auto; 
    width: auto;
    margin:auto;
  }
  .home__hero-text-container {
    margin: auto;
    text-align:center;
    margin: 5vh auto;
  }
  .home__hero-text-title {
    font-size: 9.5vw; 
    line-height: 1.1;
  }
  .home__hero-text-para {
    font-size: 4vw; 
  }
  .home__hero-contact-text-container {
    left: 0; 
    width: 80vw; 
    bottom: 0;
  }
  .home__hero-contact-text-para {
    font-size: 3vw; 
    width:100%;
  }
  .home__hero-image-container{
    display: none;
  }
  .square-button {
    width: 200px; 
    height: 40px; 
    font-size: 16px; 
    margin: 10px;
  }

  .home__info-section {
    flex-direction: column; 
    gap: 10px; 
    width: 100%; 
    height: auto; 
    margin: 10vh auto;
  }
  .home__info-title {
    font-size: 6.5vw; 
    position: relative; 
    margin-bottom: 0px; 
    letter-spacing: .5px;
    
  }
  .home__info-card-container {
    flex-direction: column; 
    width: 100%; 
    height: 100vh; 
    align-items: center;
  }
  .home__info-button {
    display: inline-flex; 
    align-items: center;  
    justify-content: center; 
    gap: 8px; 
    font-family: "Manrope", sans-serif;
    width: auto;
    padding: 25px;
    height: 40px;
    border-radius: 25px;
    background-color: #0d3a9b; 
    color: white;
    border: none;
    font-size: 3vw; 
    font-weight: 500;
    cursor: pointer; 
    transition: background-color 0.3s ease, font-size 0.3s ease; 
    z-index: 100;
  }
  .home__info-button:hover {
    background-color: #1c57d6; 
    font-size: 3.2vw;
  }
  
  .home__info-chevron-right {
    font-size: 3.2vw; 
    transition: transform 0.3s ease;
  }
  
  .home__info-button:hover .home__info-chevron-right {
    transform: translateX(4px); 
  }
  .home__card-content h3{
    font-size: 4vw;
  }
  .home__card-content .home__card-description{
    font-size: 2vw;
  }
  .home__info-card {
    width: 95%; 
    height: 100%; 
  }

  .home__careers-section {
    width: auto; 
    padding: 15px;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: auto;
  }

  .home__careers-content {
    flex-direction: column; 
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 15px;
    padding: 10px;
  }
  .home__careers-image-container {
    flex-direction: row;
    display: none;
  }
  .home__careers-image-container img {
    width: 50%; 
    height: auto; 
  }
  .home__careers-text-container {
    padding: auto;
    margin: auto;
    justify-content: center;
    text-align: center;
    z-index: 1;
    
  }

  .home__careers-text-container h2 {
    font-size: 2rem; 
    justify-content: center;
    text-align: center;
    z-index: 1;
  }
  
  .home__careers-text-container p {
    font-size: 1.2rem; 
    justify-content: center;
    text-align: center;
  }

  .contact-section {
    height: 20vh; 
    width: 100%;
    align-items: center;
    
  }
  .contactus-button{
    font-size: 2vw;
    margin: auto;
    width: auto;
    padding: 20px 30px;
    height: 40px;
    margin: auto;
  }
  .contact-title {
    font-size: 4vw; 
    width: 80%;
    padding: auto; 
    margin: auto;
  }

  .contact-para {
    font-size: 2vw; 
    width: 80%;
    padding: auto; 
    margin: auto;
  }
}



@media screen and (max-width: 400px) {
  .home__card-content h3{
    font-size: 1.3rem;
  }
  .home__card-content .home__card-description{
    font-size: .5rem;
  }
}

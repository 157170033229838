/* General Carousel Styles */
.carousel {
  font-family: "Manrope", "sans-serif";
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  background: transparent;
}

/* Carousel Content Wrapper */
.carousel-content {
  display: flex;
  width: 90%;
  gap: 20px;
}

/* Card Styles (Main and Preview Cards) */
.carousel-card {
  display:flex;
  flex-direction: column;
  width: 500px;
  height: 300px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  border:1px solid #112222;
  color: var(--text-color);
  margin:0;
  padding: 15px;
}


/* Carousel Images */
.carousel-image {
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.carousel-card:hover .carousel-image {
  transform: scale(1.05);
}

/* Navigation Controls */
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background:none;
  border: none;
  color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.carousel-control.prev {
  left: 10px;
  color: var(--text-color)
}

.carousel-control.next {
  right: 10px;
  color: var(--text-color)
}

/* Dots for Slide Navigation */
.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
}

.carousel-dots .dot {
  width: 10px;
  height: 10px;
  background: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.carousel-dots .dot.active {
  background: #333;
}

/* Responsive Design */
@media (max-width: 800px) {
  .carousel-slide .carousel-card {
    width: 80%;
  }

  .carousel-preview {
    display: none; /* Hide preview slides on smaller screens */
  }

  .carousel-control {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
  .carousel-card:nth-child(2) {
    width: 80%;
    margin:auto;
  }
  .carousel-card:nth-child(1),
  .carousel-card:nth-child(3) {
    display: none;
  }
}
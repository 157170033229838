
.careers__background {
  background-color: var(--background-color);
  font-family: "Manrope", "sans-serif";
  display:flex;
  flex-direction: column;
}

.careers__hero {
  display: flex;
  position: relative;
  background: var(--careers-hero-gradient); 
  color: white;
  height: 90vh;
  width: 100%;
  margin: auto;
  padding-top: 20vh;
}

.careers__hero-content {
  max-width: 100%;
  transform: translateY(20px);
  z-index: 3; 
  text-align: center;
  width:100%;
  margin: 0 3vw;
}

.careers__hero-stripe.primary {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  background: rgba(30, 81, 175, 0.6); 
  clip-path: polygon(50% 0, 80% 100%, 100% 100%, 100% 0); 
  z-index: 1;
}

.careers__hero-stripe.secondary {
  position: absolute;
  top: 0;
  right: -2%; 
  width: 62%; 
  height: 100%;
  background: rgba(33, 88, 189, 0.9); 
  clip-path: polygon(100% 10%, 80% 100%, 100% 100%, 100% 0); 
  z-index: 0; 
}

.careers__hero-title {
  font-size: 5vw;
  margin-bottom: 35px;
  font-weight: bold;
  color: white;
}

.careers__hero-subtitle {
  font-size: 1.2vw;
  margin: 0;
  opacity: 0.9;
  color: white;
}
.careers__container{
  display:flex;
  flex-direction: row;
  color: #ffffff;
  padding: 5vh 0;
  width: 100%;
}
.careers__header {
  text-align: left;
  width:65%;
  font-size: 1.8vw;
  margin: 5vh 5vw;
  color: var(--text-color);
}
.careers__header p{
  text-align: left;
  font-size: 1vw;
  color: var(--text-color);
}

.careers__jobList {
  width:100%;
  align-items: center;
  display:flex;
  flex-direction: column;
  margin: 5vh 0;
}

.careers__jobItem {
  background-color: white;
  color: black;
  border: 2px solid #1040c3b8;
  border-radius: 10px;
  margin-bottom: 20px;
  width:90%;
  box-shadow: 0 2px 5px rgba(35, 36, 42, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  text-align: left;
  align-items: left;
  display:flex;
  flex-direction: row;
}

.careers__jobItem-textcontainer {
  text-align: left;
  width:50%;
  margin: auto;
}
.careers__jobItem-header {
  color:black;
  left:0;
  width:100%;
}
.careers__jobItem-desc {
  color:black;
  left:0;
  text-align: left;
  width:100%;
}

.careers__jobItem:hover {
  box-shadow: 1px 1px 3px 5px rgba(43, 139, 242, 0.5);
}

.careers__link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.careers__link:hover {
  text-decoration: underline;
}

.careers__button {
  width: 35%;  
  height: 50px; 
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #0d3a9b; 
  color: white; 
  font-size: 18px; 
  font-weight: 500;                 
  cursor: pointer; 
  transition: background-color 0.3s ease, font-size 0.3s ease; 
  z-index: 1;
  margin:auto;
}

.careers__button:hover {
  background-color: #1c57d6; 
  font-size: 21px;
}
@media screen and (max-width: 800px) {
  .careers__hero-title {
    font-size: 3rem;
    margin-bottom: 35px;
    font-weight: bold;
  }
  
  .careers__hero-subtitle {
    font-size: 1.5rem;
    margin: 0;
    opacity: 0.9;
  }

  .careers__container{
    display:flex;
    flex-direction: column;
    padding: auto;
    margin:auto;
    width: 100%;
  }
  .careers__header {
    text-align: center;
    width:100%;
    font-size: 1.5rem;
    margin: 5vh 0;
  }
  .careers__header p{
    text-align: center;
    font-size: 1rem;
  }
  .careers__jobList {
    width:100%;
    margin:0;
    padding:0;
    align-items: center;
    display:flex;
    flex-direction: column;
  }
  .careers__jobItem {
    flex-direction: column;
    width: 80%;
  }

  .careers__jobItem-textcontainer {
    text-align: center;
    margin: auto;
    width:100%;
  }
  .careers__jobItem-header {
    color:black;
    left:0;
    width:100%;
    font-size: 1.5rem;
  }
  .careers__jobItem-desc {
    color:black;
    left:0;
    text-align: left;
    font-size: 1rem;
    width:100%;
  }
  .careers__jobItem-desc {
    color:black;
    text-align: center;
  }

  .careers__button {
    width: 50%;  
    height: 40px; 
    border-radius: 25px;
    background-color: #0d3a9b; 
    font-size: 16px; 
    cursor: pointer; 
    transition: background-color 0.3s ease, font-size 0.3s ease; 
    z-index: 1;
    margin: 1vh auto;
  }
  .careers__button:hover {
    background-color: #1c57d6; 
    font-size: 21px;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Patua+One');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

.Logistics {
  text-align: center;
  font-family: "Manrope", "sans-serif";
}

.logistics__container {
  background: var(--background-gradient);
}

.logistics__hero {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  position: relative; 
  background: none;
  opacity: 1;
  overflow: hidden;
}

.logistics__backdrop {
  position: absolute;
  top: 3vh;
  left: 2vw;
  height: 45%;
  background: #0d3a9b; 
  opacity: 0.9;
  z-index: -1;
}

.logistics__pallet-section {
  border-radius: 10px;
  width:85%;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 10vh;

}
.logistics__pallet-header {
  font-size: 3.5vw;
  margin:0;
  color: var(--text-color)
}
.logistics__pallet-text {
  font-size: 1vw;
  margin-bottom: 40px;
  color: var(--text-color)
}
.logistics__info-section {
  display: flex;
  flex-direction: column; 
  gap: 20px; 
  justify-content: center; 
  align-items: center; 
  height: auto; 
  width: 100%; 
  margin-bottom: 50px;
}

.logistics__info-card-container {
  display: flex; 
  justify-content: center; 
  align-items: center;
  gap: 20px; 
  width: 90%;
  height: 60vh;
}

.logistics__info-card {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: #f5a623; 
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
}

.logistics__info-card:hover {
  transform: translateY(-10px);
}

.logistics__card-content {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4); 
  padding: 10px;
  border-radius: 8px;
  z-index: 1;
}

.logistics__card-content h3 {
  margin-top: 10px;
  color: #ffffff;
  font-size: 3vw;
}

.logistics__card-content .logistics__card-description {
  margin-top: 5px;
  color: #dcdcdc;
  font-size: 1vw;
}

.logistics__card-icon img {
  width: 60px;
  height: auto;
}

.logistics__info-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.logistics__info-section p {
  font-size: 1.3rem;
  line-height: 1.5;
  max-width: 80%;
  margin: 0 auto;
}
.logistics__info-title-underline {
  width: 30%; 
  height: 10px; 
  background-color: #0d3a9b; 
  margin: 20px 0px; 

}

@media screen and (max-width: 800px) {

  .logistics__hero {
    height: 70vh; 
    width: auto;
  }

  .logistics__pallet-header{
      font-size: 6vw;
  }

  .logistics__pallet-text{
    font-size: 3vw;
  }
  
  .logistics__info-section {
    flex-direction: column; 
    gap: 10px; 
    width: 100%; 
    height: 70vh; 
  }

  .logistics__info-card-container {
    flex-direction: column; 
    width: 100%; 
    height: 100vh; 
    align-items: center;
  }

  .logistics__card-content h3{
    font-size: 7vw;
  }

  .logistics__card-content .logistics__card-description{
    font-size: 2vw;
  }

  .logistics__info-card {
    width: 95%; 
    height: 40vh; 
  }

  
}

@media screen and (max-width: 400px) {
  .logistics__card-content h3{
    font-size: 1.3rem;
  }
  .logistics__card-content .logistics__card-description{
    font-size: .5rem;
  }
}
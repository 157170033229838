@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Manrope:wght@200..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Patua+One&family=Spectral+SC&display=swap');


.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--background-color);
  padding: 5vh 3vw;
  color: var(--text-color);
  height: 200px;
  text-align: center;
  color:white;
  font-family: "Manrope", sans-serif;
}

/* Adjust the logo styling */
.footer-logo {
  height: 100%; /* Set a fixed height for the logo */
  width: auto;  /* Maintain aspect ratio */
  border-radius: 10px;
  filter: brightness(var(--logo-brightness));
}

.footer-left {
  display: flex;
  flex-direction: row;  
  align-items: center;
}

.footer-text-container {
  height: 90%;
  margin: 25px;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

.footer-text-container p {
  margin: 5px 0;
  font-size: 1rem;
  text-align: left;
  color: var(--text-color);
}

.footer-social {
  display: flex;
  gap: 35px;
  align-items: center;
}

.social-icon{
  width: 30px; 
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  transition: transform 0.2s;
  color: var(--text-color);

}

.social-icon:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

@media screen and (max-width: 800px) {

  .footer {
    height: auto; /* Let it adjust naturally */
    padding: 20px; /* Adjust padding */
    align-items: flex-start;
  }

  .footer-left {
    justify-content: center; /* Center the logo */
    margin-bottom: 20px; /* Add some margin for separation */
  }
  
  .footer-logo {
    height: auto; /* Set a fixed height for the logo */
    width: 20vw;  /* Maintain aspect ratio */
  }

  .footer-text-container {
    margin: 10px; /* Remove margin for smaller screens */
    padding: none;
  }

  .footer-text-container p {
    margin: 5px 0;
    font-size: 2.5vw;
    text-align: left;
  }
  
  .footer-social {
    flex-direction: column;
    justify-content: center; /* Center social icons */
    gap: 20px; /* Reduce gap between icons */
  }

  .social-icon img {
    width: 30px; /* Adjust size */
    height: 30px; /* Adjust size */
  }
}
@import url('https://fonts.googleapis.com/css2?family=Patua+One');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

.Transportation {
  text-align: center;
  font-family: "Manrope", "sans-serif"
}

.transportation__container {
  background: var(--background-gradient);
}

.transportation__hero {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: none;
  opacity: 1;
  overflow: hidden;
}

.transportation__hero-container {
  width: auto;
  height: auto;
}

.transportation__hero-text-container {
  position: relative;
  font-optical-sizing: auto;
  font-size: auto;
  font-style: bold;
  display: inline-block;
  overflow: hidden;
  z-index: 100;
  left: 0;
  width: 100%;
  margin: auto;
}

.transportation__hero-contact-text-para {
  background-clip: text;
  -webkit-background-clip: text;
  z-index: 1;
  font-size: 1vw;
  font-weight: 300;
  color: var(--text-color);
  text-transform: none;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  padding: 20px;
}

.transportation__hero-text-title {
  color: transparent;
  position: relative;
  z-index: 1;
  font-size: 5vw;
  font-weight: bold;
  color: var(--text-color);
  letter-spacing: 1.5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  margin: 10px 0;
  left:0;
  width: 100%;
}

.transportation__hero-text-para {
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  z-index: 1;
  font-size: 1.2vw;       
  font-weight: 300; 
  color: var(--text-color);
  text-transform: none;
  line-height: 1.2;
  letter-spacing: 1.5px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  margin: 20px 0;
}

.transportation__hero-contact-text-container {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: left;
  align-items: left;
  left: -30vw;
  width: 30vw;
  bottom: -9vw;
  height: 10vw;
}

.transportation__backdrop {
  position: absolute;
  top: 3vh;
  left: 2vw;
  height: 45%;
  background: #0d3a9b;
  opacity: 0.9;
  z-index: -1;
}

.transportation__section-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  margin: auto auto;
}

.transportation__section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: white;
  height: 400px;
  width: 60%;
  margin: 20px auto;
  background-size: cover;
  background-repeat: no-repeat; 
  background-position: center center;
  image-rendering: optimizeQuality;
}

.transportation__section-content {
  display: flex;
  flex-direction: column;
  width: 50%;
  z-index: 1;
  opacity: 1;
  animation-delay: 0.5s;
  margin: 0px 50px;
  position: absolute; 
}
.transportation__section-content-title {
  font-size: 3.5vw;
  margin-bottom: 10px;
}
.transportation__section-content-text {
  font-size: 1vw;
}
.left-align .transportation__section-content {
  left: 0;
  text-align: left;
}

.right-align .transportation__section-content {
  right: 0;
  text-align: right;
}

.transportation__info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60%; 
  margin: 0 auto; 
  margin-top: 150px;
  font-size: 1.1vw;
  font-weight: 300;
  color: var(--text-color)
}

.transportation__services_header {
  font-weight: 400;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  width: 300px;
  height:50px;
  padding:0;
  opacity: .5;
  transition: opacity 0.3s ease, font-size 0.3s ease;
}
.transportation__services_text {
  font-size: 1rem;
  font-weight: 400;
  width:auto;
  margin: auto 0;
  padding:0;
  color: var(--text-color);
}
.transportation__services_chevron{
  transform: scale(1.3, 1);
  height: auto;
  width: auto;
  margin:0;
  padding:0;
  transition: transform 0.3s ease;
  color: var(--text-color);

}
.transportation__services_header:hover {
  opacity: 1;

}
.transportation__services_header:hover .transportation__services_chevron {
  transform: scale(1.3, 1) translateY(8px);
  transition: transform 0.3s ease;
}
.transportation__info-title-underline {
  width: 30%;
  height: 10px; 
  background-color: #0d3a9b;
  margin: 20px 0px;

}

@media screen and (max-width: 800px) {

  .transportation__hero {
    height: 70vh;
    width: auto;
  }

  .transportation__hero-text-container {
    left: 0;
    width: 90%; 
    height: auto; 
    padding: 15px;

  }
  .transportation__hero-text-title {
    font-size: 8vw; 
    line-height: 1.1;
  }
  .transportation__hero-text-para {
    font-size: 4vw;
  }
  .transportation__hero-contact-text-container {
    left: 0;
    width: 80vw;
    bottom: 0;
  }
  .transportation__hero-contact-text-para {
    font-size: 3vw;
  }
  .transportation__info-section {
    display:none;
  }
  .transportation__section {
    width: 70%;
  }
  .transportation__section-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    z-index: 1;
    opacity: 1;
    animation-delay: 0.5s;
    margin: 0px 50px;
    position: absolute;
  }
  .transportation__section-content-title {
    font-size: 6vw;
    margin-bottom: 10px;
  }
  .transportation__section-content-text {
    font-size: 3vw;
  }
}
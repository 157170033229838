@import url('https://fonts.googleapis.com/css2?family=Patua+One');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');


.Contact {
  font-family: "Manrope", sans-serif;
  text-align: center;
}

.contact__container {
  display: flex;
  flex-direction: row;
  background-color: var(--background-color); 
  color: white; 
  z-index: 10;
  width:100%;
}

.contact__stripe-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 65%;
  height: 100%;
  background: var(--contact-stripe-1); 
  clip-path: polygon(50% 0, 80% 100%, 100% 100%, 100% 0);
  z-index: 0;
}

.contact__stripe-2 {
  position: absolute;
  top: 0;
  right: 0%;
  width: 62%;
  height: 100%;
  background: var(--contact-stripe-2); 
  clip-path: polygon(100% 10%, 80% 100%, 100% 100%, 100% 0);
  z-index: 0;
}


.contact__stripe-3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--contact-stripe-3); 
  clip-path: polygon(35% 0%, 0% 0%, 0% 50%, 100% 0%);
  z-index: 0;
}

.contact__information{
  width:100%;
}

.contact__header {
  position:relative;
  background: none;
  margin-left:5vw;
  margin-top: 8vh;
  color: var(--text-contact);
  text-align: left;
  z-index: 100;
}

.contact__title{
  color: var(--text-color);
  font-size: 4rem;
  margin:auto;
}
.contact__subtitle {
  color: var(--text-color);
  font-size:1.5rem;
  margin:auto;
  text-align: left;
}

.contact__info{
  z-index: 1;
  position:relative;
  margin-left:5vw;
  margin-bottom: 10vh;
  margin-top:3vh;
  z-index: 100;
  align-items: left;
  justify-content: left;
  text-align: left;
}
.contact__details{
  color: var(--text-color);
  text-align: left;
  font-size: 1rem;
  margin:0;
}
.contact__map {
  margin: 25px auto;  
  width: 100%;
  border: 2px solid black;

}

.contact__form-container {
  margin:25vh auto;
  width:100%;
  z-index: 100;
  align-items: left;
  justify-content: left;
  text-align: left;
  margin: auto 50px;

}

.contact__form-button {
  align-items: center;  
  justify-content: center; 
  gap: 8px; 
  font-family: "Manrope", sans-serif;
  width: 250px;
  height: 50px;
  border-radius: 25px;
  background-color: #0d3a9b; 
  color: white;
  border: none;
  font-size: 20px; 
  font-weight: 500;
  cursor: pointer; 
  transition: background-color 0.3s ease, font-size 0.3s ease; 
  z-index: 100;
}

.contact__form-button:hover {
  background-color: #1c57d6; 
  font-size: 22px;
}

.chevron-right {
  font-size: 1.25rem; 
  transition: transform 0.3s ease;
}

.contact__form-button:hover .chevron-right {
  transform: translateX(4px); 
}

.contact__form{
  text-align: left;
  align-items: left;

}
.contact__form-header {
  color:var(--text-color);
  width:100%;
  text-align: left;
  justify-items: left;
  align-items: left;
  font-size: 2rem;

}
.contact__form-wrapper {
  display:flex;
  flex-direction: row;
  box-sizing: border-box;
  width:100%;
  gap:10px;
  padding: 0;
  margin: 10px 0;

}
.contact__form-wrapper .contact__form-input {
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #f9f9f9;
  color: #333;
}

.contact__form-wrapper2 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap:10px;
  width:100%;
}

.contact__form-wrapper2 .contact__form-input {
  font-family: "Manrope", sans-serif;
  width: auto;
  height: 40px;
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #f9f9f9;
  color: #333;
  margin: 5px 0;
}
.contact__form-wrapper2 .contact__form-textarea {  
  font-family: "Manrope", sans-serif;
  width: auto;
  height: 125px;
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #f6f6f6;
  color: #333;
  margin-bottom: 20px;
}


.contact__message-sent {
  width:100%;
  color: var(--text-color);
  font-size: 2.5rem;
  z-index: 1;
  margin: auto 1rem;
  align-items: center;
}



@media screen and (max-width: 950px) {
  .contact__information{
    width:100%;
    margin: auto;
  }
  .contact__header {
    position:relative;
    background: none;
    margin: 0 auto;
    text-align: center;
    z-index: 100;
  }
  .contact__title{
    font-size: 3rem;
    margin:auto;
  }
  .contact__subtitle {
    font-size:.7rem;
    margin:auto;
    text-align: center;
  }
  .contact__info{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 2vh auto;
  }    
  .contact__details{
    text-align: left;
    font-size: .8rem;
    margin: auto 5vw;
  }
  .contact__map {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 25px 0;  
    padding: 0;
    width: 90%;
  }
  .contact__container {
    flex-direction: column;
    z-index: 10;
    width:100%;
  }
  
  .contact__form-container {
    width:90%;
    z-index: 100;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:auto;
    margin: 10vh auto;

    
  }
  .contact__form-button {
    margin: auto;
    width: 80%;
  }

  .contact__form{
    text-align: center;
    align-items: center;

  }
  .contact__form-header {
    width:100%;
    text-align: center;
    justify-items: center;
    align-items: center;
    font-size: 2rem;

  }
  .contact__message-sent {
    font-size: 5vw;  
    margin: 5vh auto;
  }

  .contact__stripe-1, .contact__stripe-2, .contact__stripe-3 {
    display:none;
  }
}
:root {
--background-color: rgb(255, 255, 255);
--text-color: #111111;
--hero-gradient: linear-gradient(140deg,#fcfcfc, #dbdbe1);
--hero-image-webkit: linear-gradient(330deg, rgb(255, 255, 255) 30%, transparent 69%);
--hero-image-brightness: 0.75;
--background-gradient:  linear-gradient(40deg, rgb(255, 255, 255) 20%, rgb(228, 229, 231) 100%);
--careers-hero-gradient: linear-gradient(155deg, #1c449b 20%, #87a6e9 100%);
--contact-stripe-1: linear-gradient(135deg, rgb(248, 248, 248), rgba(32, 90, 249, 0.301)); 
--contact-stripe-2:linear-gradient(135deg, rgb(254, 252, 252), rgba(31, 114, 239, 0.26));
--contact-stripe-3:linear-gradient(135deg, rgb(255, 251, 251), rgba(22, 113, 249, 0.309));
--logo-brightness: 1
}

[data-theme='dark'] {
--background-color: #111111;
--text-color: white;
--hero-gradient: linear-gradient(140deg,#111111, #08091a);
--hero-image-webkit: linear-gradient(225deg, black 6%, transparent 100%);
--hero-image-brightness: 0.4;
--background-gradient: linear-gradient(40deg, rgba(15, 15, 15, 1) 20%, rgb(8, 24, 42) 100%);
--careers-hero-gradient: linear-gradient(135deg, rgba(2, 5, 9, 0.435) 25%, rgba(0, 61, 128, .7) 100%); 
--contact-stripe-1: linear-gradient(135deg, rgb(10, 10, 10), rgb(24, 27, 55)); 
--contact-stripe-2:linear-gradient(135deg, rgb(10, 10, 10), rgb(32, 37, 73));
--contact-stripe-3:linear-gradient(135deg, rgb(10, 10, 10), rgb(30, 35, 71));
--logo-brightness: 1.5
}


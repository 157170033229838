@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Manrope:wght@200..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Patua+One&family=Spectral+SC&display=swap');

.navbar {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background: var(--background-color);
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 1000;
  animation: gradientAnimation 10s ease infinite;
}

.nav-container {
  justify-content: space-between; /* Aligns children to the edges */
  display: flex;
  width: 100vw;
  height: 60px;
  font-size: 1.1rem;
}

.nav-logo {
  width: auto;
  height: 90%;
  left:0;
  margin: auto 0;
  padding: 0;
}

.nav-logo img {                   
  cursor: pointer;
  width: auto; /* example width */
  height: 100%;
  object-fit: contain;
  margin: 0;
  padding: 0;
  filter: brightness(var(--logo-brightness));
}

.nav-links {
  display: flex;
  align-items: center;
  right: 0;
  gap: 4vw;
}

.nav-links p {
  color: #8a8585;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-links p:hover {
  color: #2b8bf2;
}

.nav-mobile-container {
  display: none; 
}

.hamburger {
  display: none; 
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 2px;
  margin: 4px 0;
  background-color: var(--text-color);
  align-items: center;
}

.mode-icon-desktop {
  margin: auto;
  width: auto;
  height: auto;
  right:0;
}

.mode-icon-mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .navbar {
    padding: 0 20px;
  }
  .nav-links.open {
    display: flex; 
    flex-direction: column;
    position: absolute;
    background: var(--background-color);
    padding: 20px 0px;
    top: 60px;
    left: 0;
    width: 100%; 
    z-index: 900; 
  }
  .nav-links {
    display: none;
  }
  .nav-mobile-container {
    display:flex;
    flex-direction: row;
    gap: 6vw;
  }
  .hamburger {
      justify-content:center;
      height: 100%;
      display: flex; 
      align-items: center;
      right: 0;
  }

  .mode-icon-desktop {
    display: none;
  }

  .mode-icon-mobile {
    display: flex;
    margin: auto;
  }
  
}


.admin-job__container {
  font-family: "Manrope", "Sans-Serif";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--background-color);
}

.admin-job__title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.admin-job__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 60vw;
  
}

.admin-job__input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: var(--text-color);
  width: 100%;
  
}

.admin-job__input-group input,
.admin-job__input-group textarea {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%; 
  font-family: "Manrope", "Sans-Serif";
}

.admin-job__input-group textarea {
  resize: vertical;
  min-height: 120px;
}

.admin-job__array-item {
  width: 100%;
  font-family: "Manrope", "Sans-Serif";
}

.admin-job__remove-button{
  padding: 0.4rem;
  font-size: .9rem;
  color: #fff;
  background-color: #0d3a9b; 
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 10vw; 
  text-align: center;
  margin: 10px auto;
  transition: background-color 0.3s ease, font-size 0.3s ease; 

}

.admin-job__remove-button:hover {
  background-color: #1c57d6; 
  font-size: 1rem;
}
.admin-job__add-button,
.admin-job__submit-button {
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0d3a9b; 
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 25vw; 
  text-align: center;
  margin: 0 auto;
  transition: background-color 0.3s ease, font-size 0.3s ease; 

}

.admin-job__add-button:hover {
  background-color: #1c57d6;
  font-size: 1.05rem; 
}

.admin-job__submit-button {
  background-color: #28a745;

}

.admin-job__submit-button:hover {
  background-color: #218838;
  font-size: 1.05rem; 

}
